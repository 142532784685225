
import {of as observableOf,  ReplaySubject ,  Observable ,  SubscriptionLike as ISubscription, Subject } from 'rxjs';

import {switchMap, distinctUntilChanged, map} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthSettings, ApiSettings } from '../../settings.class';
import { AuthService } from './auth.service';
import { snakeCase } from 'change-case';
import { EntitiesService } from '../../entities/services/entities.service';

@Injectable()
export class ProfileService implements OnDestroy {
    private userSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
    private userRequest: Observable<any>;
    private readySubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    public userData$: ReplaySubject<any> = new ReplaySubject<any>();
    private userObject: any;
    private loading = true;

    private userSubscription: ISubscription;
    private authSubscription: ISubscription;
    private userDataSubscription: ISubscription;

    constructor(private http: HttpClient,
                private authService: AuthService,
                private entitiesService: EntitiesService) {
                    this.userSubscription = this.getUser()
                                                .subscribe(res => {
                                                    this.userObject = res;
                                                    localStorage.setItem('USER-DETAILS', JSON.stringify(res))
                                                    this.readySubject.next(true);
                                                },
                                                err => {
                                                    this.authService.logout();
                                                    this.readySubject.next(true);
                                                });

                    this.authSubscription = this.authService
                                                .observeStatus()
                                                .subscribe(status => {
                                                    if (!status) {
                                                        this.userSubject.next('');
                                                        this.userObject = null;
                                                    } else {
                                                        this.getUser(true);
                                                    }
                                                });
                    this.userDataSubscription = this.userData$.
                                                subscribe( user => {
                                                    this.userObject = user;
                                                })
                }

    public logout() {
        sessionStorage.removeItem(AuthSettings.REDIRECT_KEY);
        this.authService.logout();
    }

    public whenReady() {
        return this.readySubject.asObservable();
    }

    public getUser(refresh: boolean = false) {
        if (refresh || !this.userRequest) {
            this.loading = true;
            this.userRequest = this.http.get(ApiSettings.BASE_URL + '/' + ApiSettings.ME_ENDPOINT);
            this.userRequest.subscribe(
                    res => {
                        this.userSubject.next(res);
                        this.userData$.next(res);
                        this.loading = false;
                    },
                    err => {
                        this.userSubject.error(err);
                        this.userData$.next(err);
                        this.loading = false;
                    }
            );
        }
        return this.userSubject
                   .asObservable().pipe(
                   distinctUntilChanged());
    }
    public isLoading() {
        return this.loading;
    }
    public can(permission) {
        if (!this.userObject) {
            return false;
        }
        if (this.hasRole(this.userObject, AuthSettings.SUPER_USER_ROLE)) {
            return true;
        }
        let status = false;
        if (this.userObject) {
            this.userObject.roles.forEach(role => {
                if (!status && role.permissions.filter(p => p.name === permission.toLowerCase()).length > 0) {
                    status = true;
                }
            });
        }
        return status;
    }
    public canOr(permissions) {
        if (this.isLoading()) {
            return false;
        }
        if (this.hasRole(this.userObject, AuthSettings.SUPER_USER_ROLE)) {
            return true;
        }
        let status = false;
        permissions.forEach(permission => {
            if (!status && this.can(permission)) {
                status = true;
            }
        });
        return status;
    }
    public canAnd(permissions) {
        if (this.isLoading()) {
            return false;
        }
        if (this.hasRole(this.userObject, AuthSettings.SUPER_USER_ROLE)) {
            return true;
        }
        let status = true;
        permissions.forEach(permission => {
            if (status && !this.can(permission)) {
                status = false;
            }
        });
        return status;
    }

    public hasRole(user: any, role: string): boolean {
        return user && user.roles.filter(r => r.name === role).length > 0;
    }

    public isSuperUser(): Observable<boolean> {
        return this.getUser().pipe(
                    switchMap(user => observableOf(this.hasRole(user, AuthSettings.SUPER_USER_ROLE))));
    }
    public getPermissionNames(entities: any, action: string, relation: string = '') {
        const names = []
        entities.forEach(entity => names.push(this.getPermissionName(entity, action, relation)));
        return names;
    }
    public getPermissionName(entity: string, action: string, relation: string = '') {
        if (relation !== '') {
            return action + '-relation-' + snakeCase(entity) + '-' + snakeCase(relation);
        }

        return action + '-' + snakeCase(entity);
    }

    getTeamId() {
        return this.userObject.team_id;
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
        this.authSubscription.unsubscribe();
        this.userDataSubscription.unsubscribe();
    }
}
